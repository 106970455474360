import { Injectable } from '@angular/core';
import { CrudService } from '../../shared/crud.service';
import { Observable } from 'rxjs';

@Injectable()
export class MeteoskopService {

  constructor(private crud: CrudService) {
  }

  public show(day, month): Observable<any> {
    return this.crud.show('weather_records', `${day}/${month}`);
  }

  public admin_show(month): Observable<any> {
    return this.crud.show('weather_records', month);
  }

  public update(data: any): Observable<any> {
    return this.crud.update('weather_records', data);
  }

  public current(): Observable<any> {
    return this.crud.show('weather_records', 'current');
  }

  public currentExtended(): Observable<any> {
    return this.crud.show('weather_records', 'current-extended');
  }

  public generate(month): Observable<any> {
    return this.crud.post('weather_records/generate', { month: month });
  }
}

import { Injectable } from '@angular/core';
import { CrudService } from '../../../shared/crud.service';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PersonReservationsService {
  constructor(private crud: CrudService) {
  }

  public index(type): Observable<any> {
    return this.crud.index('person_reservations', { type: type });
  }

  public create(data): any {
    return this.crud.create('person_reservations', data);
  }

  public destroy(id): any {
    return this.crud.destroy('person_reservations', id);
  }

  public available(name: string) {
    if (name.length === 0) {
      return of({ data: true });
    } else {
      return this.crud.get('person_reservations/available/' + name, {});
    }
  }
}

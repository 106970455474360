import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from './toastr/toastr.service';

export function handleErrorResponse(toastrService: ToastrService, httpErrorResponse: HttpErrorResponse, subtitle = '', options = {}) {
  if (!toastrService) {
    return;
  }

  let errMsg;

  if (httpErrorResponse.error) {
      errMsg = httpErrorResponse.error.message || httpErrorResponse.error.toString();

      if (errMsg === 'This action is unauthorized.') {
        errMsg = 'Táto akcia nie je autorizovaná.';
      }
  } else {
      errMsg = 'Niečo sa pokazilo. Skúste zopakovať vašu akciu.';
  }

  toastrService.notification(errMsg, 'error', subtitle, options);

  return errMsg;
}

import { Injectable }       from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild } from '@angular/router';
import { AuthService } from "../auth.service";
import { ToastrService } from '../../../shared/toastr/toastr.service';

@Injectable()
export class AdminGuard implements CanActivate, CanActivateChild {
  constructor(
    private authService: AuthService,
    private router: Router,
    private toastrService: ToastrService,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkAuthorization(route);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(route, state);
  }

  checkAuthorization(route: ActivatedRouteSnapshot): boolean {
    if (this.authService.isAdmin()) {
      return true;
    }

    let roles = route.data["roles"] as Array<string>;

    if (!roles) {
      roles = [];
    }

    for (const role of roles) {
      if (this.authService.hasRole(role)) {
        return true;
      }
    }

    this.toastrService.notification('Do tejto sekcie nemáte prístup', 'warning');

    this.router.navigate(['/avardan', 'announcements']);

    return false;
  }
}

import { Injectable } from '@angular/core';
import {
  CanActivate, Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild,
} from '@angular/router';
import { AuthService } from "../auth.service";
import { ToastrService } from '../../../shared/toastr/toastr.service';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private authService: AuthService,
    private toastrService: ToastrService,
    private router: Router,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.checkLogin()) {
      return this.checkTutorialStatus(state.url);
    }

    return false;
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(route, state);
  }

  checkLogin(): boolean {
    if (!this.authService.isLoggedIn()) {
      this.router.navigate(['/index']);

      return false;
    }

    return true;
  }

  checkTutorialStatus(url): boolean {
    if (!this.authService.updatedGdprConsent() && url.indexOf('gdpr') === -1) {
      this.router.navigate(['/tutorial/gdpr']);

      return false;
    }

    if (
      this.authService.tutorialStatus() == 'new' &&
      url.indexOf('tutorial') === -1 &&
      url.indexOf('library') === -1 &&
      url.indexOf('realias') === -1 &&
      url.indexOf('faq') === -1 &&
      url.indexOf('rules') === -1 &&
      url.indexOf('settings') === -1 &&
      url.indexOf('forum') === -1
    ) {
      this.router.navigate(['/tutorial']);

      this.toastrService.notification('Do tejto sekcie nemáte prístup kým nedokončíte kvízovú časť tutoriálu', 'warning');

      return false;
    }

    return true;
  }
}

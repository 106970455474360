import { Injectable } from '@angular/core';
import { interval, ReplaySubject, Subscription } from 'rxjs';
import { AuthService } from '../core/auth/auth.service';
import { handleErrorResponse } from './handle_error_response';
import { AppVersionService } from '../core/app-version.service';
import { ActivityReadService } from './activity_read.service';
import { ToastrService } from './toastr/toastr.service';

@Injectable({
  providedIn: 'root',
})
export class RememberballService {
  private _source = new ReplaySubject<any>(null);
  public notifier = this._source.asObservable();
  private userSubscription: Subscription;
  private user = null;
  REFRESH_INTERVAL = 90 * 1000;
  timerSubscription: Subscription;
  isLoadingReport = false;
  lastRefresh: number = null;
  report = {};

  constructor(
    private auth: AuthService,
    private appVersionService: AppVersionService,
    private activityReadService: ActivityReadService,
    private toastrService: ToastrService,
  ) {
    this.userSubscription = this.auth.user.subscribe(user => {
      this.user = user;

      if (this.user) {
        this.loadReport();
      }
    });
  }

  public poke(data) {
    this._source.next(data);
  }

  public subscribeToData(): void {
    if (!this.timerSubscription) {
      this.timerSubscription = interval(this.REFRESH_INTERVAL).subscribe(() => {
        this.loadReport();
      });
    }
  }

  public unsubscribe(): void {
    this.timerSubscription.unsubscribe();
    this.timerSubscription = null;
  }

  loadReport(force = false) {
    if (!this.user) {
      return;
    }

    if (!this.isLoadingReport || force) {
      this.isLoadingReport = true;
      this.lastRefresh = (new Date()).getTime();

      this.activityReadService.report().subscribe((res) => {
        this.lastRefresh = (new Date()).getTime();
        this.report = res.body.data;
        this.auth.setActivityReadReport(this.report);
        this.appVersionService.updateAppVersion(res.headers.get('durmstrang-int-app-version'));
        this.isLoadingReport = false;

        this.poke(res.body.data);
      }, (err) => {
        this.isLoadingReport = false;
        handleErrorResponse(this.toastrService, err);
      });
    } else {
      console.warn('remembrall loading already in progress');
    }
  }

  onDestroy(statusId) {
    
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AuthGuard } from "./core/auth/guards/auth-guard.service";
import { QuicklinkModule, QuicklinkStrategy } from 'ngx-quicklink';

const routes: Routes = [
  {
    path: 'index',
    loadChildren: () => import('app/index/index.module').then(m => m.IndexModule)
  },
  {
    path: 'rooms',
    loadChildren: () => import('app/rooms/rooms.module').then(m => m.RoomsModule)
  },
  {
    path: '',
    loadChildren: () => import('app/game/game.module').then(m => m.GameModule),
    canActivate: [AuthGuard],
  },
  {
    path: '**',
    redirectTo: '/'
  }
];

@NgModule({
  imports: [
    QuicklinkModule,
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload',
      preloadingStrategy: QuicklinkStrategy,
    })
  ],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule {
}

import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { HttpClient } from '@angular/common/http';

@Injectable()
export class CrudService {
  constructor(private http: HttpClient) {
  }

  protected indexRoute(path_name) {
    return `${environment.API_ENDPOINT}${path_name}`;
  }

  protected showRoute(path_name, id) {
    return `${environment.API_ENDPOINT}${path_name}/${id}`;
  }

  protected createRoute(path_name) {
    return environment.API_ENDPOINT + path_name;
  }

  protected editRoute(path_name) {
    return environment.API_ENDPOINT + path_name + '/';
  }

  protected destroyRoute(path_name) {
    return environment.API_ENDPOINT + path_name + '/';
  }

  public index(path_name, request_data: any = {}): Observable<any> {
    request_data.page = request_data.page ? request_data.page : 1;
    request_data.order_by = request_data.orderBy ? request_data.orderBy : 'id';
    request_data.direction = request_data.direction ? request_data.direction : 'desc';
    request_data.per_page = request_data.perPage ? request_data.perPage : 25;

    if (request_data.perPage) {
      delete request_data['perPage']
    }
    if (request_data.orderBy) {
      delete request_data['orderBy']
    }

    Object.keys(request_data).forEach((key) => (request_data[key] == null) && delete request_data[key]);

    return this.http.get(this.indexRoute(path_name), { params: request_data });
  }

  public show(path_name, id: number | string | string[], options = {}): Observable<any> {
    return this.http.get(this.showRoute(path_name, id), { params: options });
  }

  public create(path_name, data: any): Observable<any> {
    return this.http.post(this.createRoute(path_name), data);
  }

  public update(path_name, data: any): Observable<any> {
    return this.http.patch(this.editRoute(path_name) + data.id, data);
  }

  public destroy(path_name, id: number, data: any = {}): Observable<any> {
    let options = {
      headers: null,
      body: data,
    };

    return this.http.delete(this.destroyRoute(path_name) + id, options);
  }

  public post(path_name, data = {}, options = {}): Observable<any> {
    return this.http.post(this.createRoute(path_name), data, options);
  }

  public patch(path_name, data): Observable<any> {
    return this.http.patch(this.createRoute(path_name), data);
  }

  public get(path_name, data = {}): Observable<any> {
    return this.http.get(this.showRoute(path_name, ''), data);
  }
}

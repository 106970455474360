import { Injectable } from '@angular/core';
import { CrudService } from '../../../shared/crud.service';
import { Observable } from 'rxjs';

@Injectable()
export class RequestsService {

  constructor(private crud: CrudService) {
  }

  public index(options = {}): Observable<any> {
    return this.crud.index('request_records', options);
  }

  public show(id: number): Observable<any> {
    return this.crud.show('request_records', id);
  }

  public create(data: any): Observable<any> {
    return this.crud.create('request_records', data);
  }

  public vote(requestId, data): Observable<any> {
    return this.crud.post(`request_records/${requestId}/vote`, data);
  }

  public updateRequestData(requestId, data): Observable<any> {
    return this.crud.post(`request_records/${requestId}/update_data`, data);
  }

  public update(data): Observable<any> {
    return this.crud.update('request_records', data);
  }
}

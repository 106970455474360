import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/index';
import { CrudService } from './crud.service';

@Injectable({
  providedIn: 'root',
})
export class MagicAbilitiesService {

  constructor(private crud: CrudService) {
  }

  public index(options = {}): Observable<any> {
    return this.crud.index('magic_abilities', options);
  }

  public update(data): any {
    return this.crud.update('magic_abilities', data);
  }
}


import {throwError as observableThrowError, empty as observableEmpty,  Observable ,  Subject } from 'rxjs';

import {tap, catchError, switchMap} from 'rxjs/operators';

import { AuthService } from './auth.service';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from '../../shared/toastr/toastr.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  isRefreshingToken: boolean = false;
  tokenRefreshedSource = new Subject();
  tokenRefreshed$ = this.tokenRefreshedSource.asObservable();

  constructor(private auth: AuthService,
              private router: Router,
              private toastrService: ToastrService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = this.addAuthHeader(request);

    return next.handle(request).pipe(catchError((res, caught) => {
      switch (res.status) {
        case 400:
          if (res.error && (res.error.error === 'token_expired' || res.error.message === 'The token has been blacklisted')) {
            this.logoutUser();
            return observableEmpty();
          }
          break;
        case 401:
          return this.refreshToken().pipe(
            switchMap(() => {
              request = this.addAuthHeader(request);
              return next.handle(request);
            }),
            catchError(() => {
              this.logoutUser();
              return observableEmpty();
            }),);
      }

      return observableThrowError(res);
    }));
  }

  logoutUser() {
    this.auth.logout();
    this.router.navigate(['/index']);
    this.toastrService.notification('Platnosť prihlásenia vypršala. Musíte sa opäť prihlásiť.', 'error');
  }

  addAuthHeader(request) {
    const token = this.auth.getAuthToken();

    if (token) {
      return request.clone({
        setHeaders: {
          'Authorization': `Bearer ${token}`,
          'X-JWT-TOKEN': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
    }

    return request;
  }

  refreshToken() {
    if (this.isRefreshingToken) {
      return new Observable(observer => {
        this.tokenRefreshed$.subscribe(() => {
          observer.next();
          observer.complete();
        });
      });
    } else {
      this.isRefreshingToken = true;

      return this.auth.refreshToken().pipe(
        tap(() => {
          this.isRefreshingToken = false;
          this.tokenRefreshedSource.next();
        }));
    }
  }
}

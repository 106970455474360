import { Injectable } from '@angular/core';
import * as snowf from 'snowf';

@Injectable({
  providedIn: 'root',
})
export class SnowfService {
  constructor() {
  }

  public init() {
    snowf.init({
      size: 5,
      amount: 30,
    });
  }
}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class BrowserNotificationsService {
  supportsNotification = true;

  constructor() {
    this.supportsNotification = 'Notification' in window;
  }

  public sendNotification(title, text) {
    if (!this.supportsNotification) {
      return;
    }

    if ((window.Notification && Notification.permission == 'granted') || this.isNewNotificationSupported()) {
      Notification.requestPermission((p) => {
        try {
          const notification = new Notification(title, {
            body: text,
            icon: 'favicon.ico',
          });
          notification.onclick = function () {
            window.focus();
          };
        } catch (e) {
          console.error('Prehliadač nepodporuje notifikácie', e);
        }
      });
    }
  }

  isNewNotificationSupported() {
    if (!window.Notification || !Notification.requestPermission)
      return false;
    if (Notification.permission == 'granted')
      throw new Error('You must only call this *before* calling Notification.requestPermission(), otherwise this feature detect would bug the user with an actual notification!');
    try {
      new Notification('');
    } catch (e) {
      if (e.name == 'TypeError')
        return false;
    }
    return true;
  }
}

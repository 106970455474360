import { Injectable } from '@angular/core';
import { CrudService } from '../crud.service';
import { Observable } from 'rxjs/index';

@Injectable({
  providedIn: 'root',
})
export class BoltsService {
  constructor(private crud: CrudService) {
  }

  public bolt(record_id: number, type: string): Observable<any> {
    return this.crud.post(`bolts/${record_id}/${type}`, {});
  }

  public loadBolters(record_id: number, type: string): Observable<any> {
    return this.crud.get(`bolts/${record_id}/${type}/bolters`, {});
  }
}

import { Injectable } from '@angular/core';
import * as SunCalc from 'suncalc';

@Injectable({
  providedIn: 'root',
})
export class SuncalcService {
  isDay = false;
  lat = 66.358536;
  lon = 14.324760;
  times;

  constructor() {
    const current = new Date();
    this.times = this.getTimes();
    this.isDay = this.checkIsDay();
  }

  public getTimes(forDate?: Date) {
    if (!forDate) {
      forDate = new Date();
    }

    return SunCalc.getTimes(forDate, this.lat, this.lon);
  }

  public getMoonPhase() {
    const moonData = SunCalc.getMoonIllumination(new Date());

    if (moonData.phase < 0.01) {
      return 'Nov';
    } else if (moonData.phase < 0.24) {
      return 'Dorastajúci kosák';
    } else if (moonData.phase < 0.27) {
      return 'Prvá štvrť';
    } else if (moonData.phase < 0.49) {
      return 'Dorastajúci polmesiac';
    } else if (moonData.phase < 0.52) {
      return 'Spln mesiaca';
    } else if (moonData.phase < 0.74) {
      return 'Cúvajúci polmesiac';
    } else if (moonData.phase < 0.77) {
      return 'Posledná štvrť';
    } else if (moonData.phase > 0.98) {
      return 'Nov';
    } else {
      return 'Cúvajúci kosák';
    }
  }

  public checkIsDay() {
    const current = new Date();
    this.times = this.getTimes();

    if (this.times.sunrise.toString() === 'Invalid Date') {
      return true;
    }

    return current >= this.times.sunrise && current <= this.times.sunset;
  }
}

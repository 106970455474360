import { NgModule } from '@angular/core';
import { CustomToastrComponent } from './custom-toastr/custom-toastr.component';
import { ToastrComponent } from './toastr/toastr.component';
import { CommonModule } from '@angular/common';
import { SafePipe } from './safe.pipe';
import { MatTooltipModule } from '@angular/material/tooltip';
import {ScrollToTopComponent} from './scroll-to-top/scroll-to-top.component';

@NgModule({
  imports: [
    CommonModule,
    MatTooltipModule,
  ],
  declarations: [
    CustomToastrComponent,
    ToastrComponent,
    SafePipe,
    ScrollToTopComponent,
  ], exports: [
    CustomToastrComponent,
    ToastrComponent,
    SafePipe,
    ScrollToTopComponent,
    MatTooltipModule
  ]
})

export class SharedMinimalModule {
}

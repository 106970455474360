import { Injectable } from '@angular/core';
import * as io from 'socket.io-client';
import { environment } from "environments/environment";
import { AuthService } from '../core/auth/auth.service';
import { RememberballService } from './rememberball.service';
import { Router } from '@angular/router';
import { ToastrService } from './toastr/toastr.service';
import { ReplaySubject } from 'rxjs';

@Injectable()
export class SocketService {
  userId = null;
  public socket;

  private _socketConnectedSource = new ReplaySubject<any>(1);
  public socketConnected = this._socketConnectedSource.asObservable();

  constructor(
    private auth: AuthService,
    private remembrallService: RememberballService,
    private router: Router,
    private toastrService: ToastrService,
  ) {
    this._socketConnectedSource.next(false);
  }

  connect(query) {
    console.debug('connecting to socket');

    this.socket = io.connect(`${environment.WEBSOCKETS_ENDPOINT}?${query}`, {
      transports: ['websocket'],
      rejectUnauthorized: false,
      reconnectionDelay: 1000,
      reconnection: true,
      reconnectionAttempts: 10,
      upgrade: false,
    });

    this.registerSocketEvents();
  }

  connectUser(userId) {
    if (userId != this.userId) {
      this.userId = userId;

      if (!this.socket) {
        this.connect(`user_id=${userId}`);
      } else {
        this.socket.emit('join_user', userId);
      }
    } else {
      console.warn('Updating socket user as user id is the same', userId);
      this.socket.emit('update_user', userId);
    }
  }

  disconnect() {
    this.socket.disconnect();
  }

  registerSocketEvents() {
    this.socket.on('connect', () => {
      console.debug('socket connect');
      this._socketConnectedSource.next(true);
    });

    this.socket.on('connect_error', () => {
      console.debug('socket connect_error');
      this._socketConnectedSource.next(false);
    });

    this.socket.on('disconnect', () => {
      console.debug('socket disconnect');
      this._socketConnectedSource.next(false);
    });

    this.socket.on('error', (err) => {
      console.debug('socket error');
      this._socketConnectedSource.next(false);
      // We don't report these errors because it spams sentry
      // and then dries out the usage limit

      // if (err.description) throw err.description;
      // else throw err; // Or whatever you want to do
    });

    this.socket.on('reconnect', () => {
      console.debug('socket reconnect');
      this._socketConnectedSource.next(true);
    });

    // application specific events
    this.socket.on('reload_user_data', () => {
      this.auth.reloadUserFromServer();
    });

    this.socket.on('reload_rememberball', () => {
      this.remembrallService.loadReport();
    });

    this.socket.on('reload_rp_points', () => {
      this.auth.reloadRpPoints();
    });

    this.socket.on('room_not_found', () => {
      this.router.navigate(['/durmstrang', 'map']);
      this.toastrService.notification('Miestnosť nebola nájdená', 'error');
    });
  }

  unregisterSocketEvents() {
    this.socket.off('connect_error');
    this.socket.off('disconnect');
    this.socket.off('reload_user_data');
    this.socket.off('error');
    this.socket.off('reconnect');
    this.socket.off('room_not_found');
  }
}

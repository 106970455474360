import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { CrudService } from './crud.service';

@Injectable()
export class ClubhousesService {

  constructor(private crud: CrudService) {
  }

  public index(type = null): Observable<any> {
    if (type == null) {
      return this.crud.index('clubhouses/assigned');
    } else {
      return this.crud.index('clubhouses', { type: type });
    }
  }

  public show(id: number, options = {}): Observable<any> {
    return this.crud.show('clubhouses', id, options);
  }

  public create(data): any {
    return this.crud.create('clubhouses', data);
  }

  public update(data): any {
    return this.crud.update('clubhouses', data);
  }

  public assignableClubhouse(): any {
    return this.crud.index('clubhouses/assignable_clubhouses');
  }

  public destroy(id): any {
    return this.crud.destroy('clubhouses', id);
  }

  public addMember(data): Observable<any> {
    return this.crud.post(`clubhouse_members/add_member/${data.clubhouse_id}/${data.user_id}/`, data);
  }

  public removeMember(data): Observable<any> {
    return this.crud.post(`clubhouse_members/remove_member/${data.clubhouse_id}/${data.user_id}/`);
  }

  public addFamilyMember(data): Observable<any> {
    return this.crud.post(`clubhouses/${data.clubhouse_id}/add_to_family`, data);
  }

  public removeFamilyMember(data): Observable<any> {
    return this.crud.post(`clubhouses/${data.clubhouse_id}/remove_from_family`, data);
  }

  public setCrest(data): Observable<any> {
    return this.crud.post(`clubhouses/${data.clubhouse_id}/set_crest`, data);
  }

  public orderedQuidditchTeams(): Observable<any> {
    return this.crud.get('clubhouses/quidditch');
  }

  public awardReward(data): Observable<any> {
    return this.crud.post(`clubhouses/${data.clubhouse_id}/award`, data);
  }

  public resetPoints(): Observable<any> {
    return this.crud.post('clubhouses/quidditch-reset');
  }
}

import { Injectable } from '@angular/core';
import { CrudService } from './crud.service';
import { Observable } from 'rxjs';

@Injectable()
export class TagsService {
  constructor(private crud: CrudService) {
  }

  public index(options = {}): Observable<any> {
    return this.crud.index('tags', options);
  }

  public update(data): Observable<any> {
    return this.crud.patch(`tags/${data.id}`, data);
  }

  public destroy(id) {
    return this.crud.destroy('tags', id);
  }

  public search(term): Observable<any> {
    return this.crud.get('tags/search', { params: { term: term }});
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SectionNameService {
  private _nameSource = new BehaviorSubject<string>('Vitajte');
  public loading = this._nameSource.asObservable();

  constructor() {
  }

  setTitle(title: string) {
    this._nameSource.next(title);
  }
}

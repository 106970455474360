import { of as observableOf, Observable, of } from 'rxjs';
import { share, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { RegisterUser } from "./register-user";
import { PasswordReset } from "./password-reset";
import { environment } from "../../../environments/environment";
import { UserSettings } from "./user-settings";
import { CrudService } from "../../shared/crud.service";
import { HttpClient } from '@angular/common/http';

@Injectable()
export class UserService {
  constructor(private http: HttpClient,
              private crud: CrudService,) {
  }

  public register(data: RegisterUser) {
    return this.http.post<{ message: string }>(environment.API_ENDPOINT + 'register', data).pipe(
      map((res) => res.message));
  }

  public sendPasswordResetEmail(data: { email: string }) {
    return this.http.post<{ message: string }>(environment.API_ENDPOINT + 'password/email', data);
  }

  public resetPassword(data: PasswordReset): Observable<any> {
    return this.http.post(environment.API_ENDPOINT + 'password/reset', data);
  }

  public available(property: string, value: string) {
    if (value.length === 0) {
      return of(null);
    } else {
      return this.http.get(environment.API_ENDPOINT + 'users/available/' + property + '/' + value);
    }
  }

  public changeSettings(id: number, data: UserSettings) {
    return this.http.post<{ message: string }>(environment.API_ENDPOINT + 'users/' + id + '/update', data).pipe(
      map((res) => res.message));
  }

  public getUsers(options = {}): Observable<any> {
    return this.crud.index('users', options);
  }

  public getUserProfile(username: string): Observable<any> {
    return this.crud.show('users/profile', username);
  }

  public getNotYetEmailedCount(): Observable<any> {
    return this.http.get(environment.API_ENDPOINT + 'users/not_yet_emailed');
  }

  public sendEmailConfirmations() {
    return this.http.post(environment.API_ENDPOINT + 'users/send_email_confirmations', {});
  }

  public sendEmailConfirmation(userId): any {
    return this.http.post(environment.API_ENDPOINT + `users/${userId}/send_email_confirmation`, {});
  }

  public verifyRegistration(token) {
    return this.http.get<{ status: string, message: string }>(environment.API_ENDPOINT + 'register/verify/' + token);
  }

  public getOnlineUsers() {
    return this.crud.index('users/online', { page: 0, orderBy: 'id', direction: 'asc' });
  }

  public reloadUser(): Observable<any> {
    return this.http.get<{ users: string[] }>(environment.API_ENDPOINT + 'users/reload', { observe: 'response' });
  }

  public reloadRpPoints(): Observable<any> {
    return this.http.get<{ users: string[] }>(environment.API_ENDPOINT + 'users/reload_rp_points');
  }

  public updateProfile(data) {
    return this.crud.patch(`users/${data.id}/update_profile`, data);
  }

  public updateAdmin(id, attribute, value) {
    let data = {};
    data[attribute] = value;

    return this.crud.patch(`users/${id}/update_admin`, data);
  }

  public destroy(id, password = null) {
    return this.crud.destroy('users', id, { password: password });
  }

  public loginAs(id) {
    return this.crud.post(`users/login_as/${id}`, id);
  }

  public card(id) {
    return this.crud.get(`users/${id}/card`, {});
  }

  public statistics(id) {
    return this.crud.get(`users/${id}/statistics`, {});
  }

  public addRpPoints(data) {
    return this.crud.post(`users/${data.user_id}/add_rp_points`, data);
  }

  public deductRpPoints(data) {
    return this.crud.post(`users/${data.user_id}/deduct_rp_points`, data);
  }

  public exitTutorial() {
    return this.crud.post(`users/exit_tutorial`);
  }

  public purchaseCharacterSlot() {
    return this.crud.post('users/purchase_character_slot', {});
  }

  public availableTitles(username) {
    return this.crud.get(`users/${username}/available_titles`);
  }

  public heartbeat(location) {
    return this.crud.post('users/heartbeat', { location: location }, { observe: 'response' });
  }

  public list(params = {}) {
    return this.crud.index('users/list', params);
  }

  public gdprConsent() {
    return this.crud.post('users/gdpr_consent');
  }

  public updateCloak() {
    return this.crud.post('users/activity_tracking');
  }

  public loadNotebook() {
    return this.crud.get('users/notebook');
  }

  public sharedIpsReport() {
    return this.crud.get('users/ip_stamps_report')
  }
}

import { Injectable } from '@angular/core';
import { CrudService } from '../../shared/crud.service';
import { Observable } from 'rxjs/index';

@Injectable()
export class QuestsService {
  constructor(private crud: CrudService) {
  }

  public index(options = {}): Observable<any> {
    return this.crud.index('quests', options);
  }

  public show(id: number): Observable<any> {
    return this.crud.show('quests', id);
  }

  public create(data): any {
    return this.crud.create('quests', data);
  }

  public update(data): any {
    return this.crud.update('quests', data);
  }

  public destroy(id): any {
    return this.crud.destroy('quests', id);
  }

  public enroll(id): any {
    return this.crud.post(`quests/${id}/enroll`, {});
  }

  public leave(id): any {
    return this.crud.post(`quests/${id}/leave`, {});
  }

  public updateRegistrationState(id, data): any {
    return this.crud.patch(`quests/${id}/change_state`, data);
  }

  public updateChatRoom(id, data): any {
    return this.crud.patch(`quests/${id}/change_room`, data);
  }

  public markAsFinished(id, data): any {
    return this.crud.post(`quests/${id}/finish`, data);
  }

  public reject(id, data): any {
    return this.crud.post(`quests/${id}/reject`, data);
  }

  public fail(id, data): any {
    return this.crud.post(`quests/${id}/fail`, data);
  }

  public loadPage(page, type): Observable<any> {
    return this.crud.index(`quests/type/${type}`, { page: page });
  }
}

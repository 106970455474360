import { Injectable, EventEmitter } from '@angular/core';

@Injectable()
export class ToastrService {
  alertOccurred = new EventEmitter<string[]>();

  notification(text: string, type = 'success', title = null, options = {}) {
    this.alertOccurred.emit([type, text, title, options]);
  }

  handleRequestRecordResponse(res) {
    if (res.message == 'Žiadosť bola schválená a vykonaná' ||
      res.message == 'Žiadosť bola odoslaná') {
      this.notification(res.message, 'success');
    } else {
      this.notification(res.message, 'warning');
    }
  }
}

import { Injectable } from '@angular/core';
import { CrudService } from "../../shared/crud.service";

@Injectable()
export class SettingsService {
  constructor(private crud: CrudService) {
  }

  index = () => this.crud.index('settings');
  update = (data) => this.crud.update('settings', data);
  show = (key) => this.crud.show('settings', key);
}

import { Injectable } from '@angular/core';
import { CrudService } from '../../../shared/crud.service';
import { Observable } from 'rxjs';

@Injectable()
export class RequestCommentsService {

  constructor(private crud: CrudService) {
  }

  public createReply(data: any): Observable<any> {
    return this.crud.create('request_comments/' + data.thread_id, data);
  }
}

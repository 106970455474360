import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";

@Injectable()
export class LoaderService {
  private _loadingSource = new BehaviorSubject<boolean>(false);
  public loading = this._loadingSource.asObservable();

  constructor() {
  }

  setLoading(loading: boolean) {
    this._loadingSource.next(loading);
  }
}

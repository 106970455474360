import { Injectable } from '@angular/core';
import { CrudService } from '../crud.service';
import { Observable } from 'rxjs';

@Injectable()
export class NotificationsService {

  constructor(private crud: CrudService) {
  }

  public index(options = {}): Observable<any> {
    return this.crud.index('notifications', options);
  }

  public widget(): Observable<any> {
    return this.crud.index('notifications/widget');
  }

  public markAsRead(id): Observable<any> {
    return this.crud.post(`notifications/${id}/read`, {});
  }
}

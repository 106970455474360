import { Injectable } from '@angular/core';
import { ToastrService } from '../shared/toastr/toastr.service';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class AppVersionService {
  private appVersion = null;

  constructor(
    private toastrService: ToastrService,
    private sanitizer: DomSanitizer,
  ) {
  }

  updateAppVersion(appVersion: string) {
    if (this.appVersion !== null && this.appVersion != appVersion) {
      this.triggerReload();
    }

    this.appVersion = appVersion;
  }

  private triggerReload() {
    this.toastrService.notification(
      'Bola vydaná nová verzia aplikácie. Prosím obnovte si stránku.',
      'warning',
      'Používate neaktuálnu verziu',
      {
        disableTimeOut: true,
        enableHtml: true,
        tapToDismiss: false,
        closeButton: false,
      },
    );
  }
}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from "./core/core.module";
import { AuthService } from "./core/auth/auth.service";
import { ToastrService } from "./shared/toastr/toastr.service";
import { CharacterService } from "./game/characters/character.service";
import { CharacterStatusService } from "./game/characters/character-status.service";
import { ToastrModule } from "ngx-toastr";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { ScrollSpyModule } from "ngx-scrollspy";
import { CrudService } from "./shared/crud.service";
import { LoaderService } from "./shared/loader/loader.service";
import { TextsService } from './shared/texts.service';
import { SectionNameService } from './game/core/section-name.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './core/auth/jwt.interceptor';
import { ActivityReadService } from './shared/activity_read.service';
import { ChatRoomService } from "./durmstrang/chat-room.service";
import { SocketService } from './shared/socket.service';
import { NotificationsService } from './shared/notifications/notifications.service';
import { TagsService } from './shared/tags.service';
import { CustomToastrComponent } from './shared/custom-toastr/custom-toastr.component';

import localeSk from '@angular/common/locales/sk';
import { registerLocaleData } from '@angular/common';

import * as Sentry from "@sentry/browser";
import { SentryErrorHandler } from './core/sentry';
import { Integrations } from "@sentry/tracing";
import { environment } from '../environments/environment';
import { WandsService } from './avardan/wand-shop/wands.service';
import { MeteoskopService } from './avardan/meteoskop/meteoskop.service';
import { PersonReservationsService } from './avardan/avatars/person_reservations/person-reservations.service';
import { BoltsService } from './shared/bolts/bolts.service';
import { RequestsService } from './game/ministry/requests/requests.service';
import { MagicalTalentsService } from './game/magical-talents/magical-talents.service';
import { PricesService } from './shared/prices.service';
import { MagicAbilitiesService } from './shared/magic-abilities.service';
import { WindowRefService } from './shared/window-ref.service';
import { SharedMinimalModule } from './shared/shared-minimal.module';

import { Angulartics2Module } from 'angulartics2';
import { EventBrokerService } from './shared/event-broker.service';
import { RouteReuseStrategy } from '@angular/router';
import { CustomRouteReuseStategy } from './shared/custom_route_reuse_stategy';
import { RequestCommentsService } from './game/ministry/requests/request-comments.service';
import { CreaturesService } from './avardan/creatures.service';
import { SettingsService } from './administration/settings/settings.service';
import { BrowserNotificationsService } from './shared/browser-notifications.service';
import { QuestsService } from './avardan/quests/quests.service';
import { FeatureFlagService } from './core/feature-flag.service';
import { ClubhousesService } from './shared/clubhouses.service';

if (environment.production) {
  Sentry.init({
    dsn: "https://4d92c4a06dd748d6bffc943869944974@sentry.io/1294724",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.01,
    allowUrls: [
      /https?:\/\/((www)\.)?durmstrang\.sk/
    ],
    ignoreErrors: [
      // Observed errors from perfect scrollbar
      'setting getter-only property "pageY"',
      'Attempted to assign to readonly property.',
      'undefined is not an object (evaluating \'e.subtract\')',
      'null is not an object (evaluating \'t.scrollTop\')',

      // Errors from angular2-draggable
      'Cannot read property \'subtract\' of undefined',

      // Errors for images?
      'Non-Error promise rejection captured with value: Failed to get image dimensions for',

      // New app versions released
      'ChunkLoadError: Loading chunk',

      // Random plugins/extensions
      'top.GLOBALS',
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      'Can\'t find variable: ZiteReader',
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      // Facebook borked
      'fb_xd_fragment',
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
      // reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage'
    ],
  });
}

registerLocaleData(localeSk);

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedMinimalModule,
    FormsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-right',
      newestOnTop: false,
      closeButton: true,
      toastComponent: CustomToastrComponent
    }),
    ScrollSpyModule.forRoot(),
    Angulartics2Module.forRoot(),

    CoreModule,
    AppRoutingModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'sk-SK' },
    // { provide: ErrorHandler, useFactory: errorHandlerFactory },
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: APP_INITIALIZER, useFactory: (myInitService:FeatureFlagService)=>()=>myInitService.setupFeatureFlags(), deps: [FeatureFlagService], multi: true },
    { provide: RouteReuseStrategy, useClass: CustomRouteReuseStategy },
    AuthService,
    ToastrService,
    CharacterService,
    CharacterStatusService,
    CrudService,
    LoaderService,
    TextsService,
    SectionNameService,
    ActivityReadService,
    ChatRoomService,
    SocketService,
    MeteoskopService,
    NotificationsService,
    TagsService,
    WandsService,
    PersonReservationsService,
    BoltsService,
    RequestsService,
    RequestCommentsService,
    MagicalTalentsService,
    PricesService,
    MagicAbilitiesService,
    WindowRefService,
    EventBrokerService,
    CreaturesService,
    SettingsService,
    BrowserNotificationsService,
    QuestsService,
    ClubhousesService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }

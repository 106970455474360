import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/index';
import { CrudService } from './crud.service';
import { map } from 'rxjs/internal/operators';
import { of as observableOf } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class PricesService {

  constructor(private crud: CrudService) {
  }

  public index(options = {}): Observable<any> {
    return this.crud.index('prices', options);
  }

  public update(data): any {
    return this.crud.update('prices', data);
  }

  public pricelist(): Observable<any> {
    return this.crud.index('prices/pricelist', {});
  }

  public price(name): Observable<any> {
    return this.crud.index(`prices/${name}`, {});
  }
}

import { Injectable } from '@angular/core';
import { CrudService } from '../../shared/crud.service';

@Injectable()
export class WandsService {
  constructor(private crud: CrudService) {
  }

  public create(data): any {
    return this.crud.create('wands', data);
  }

  public createSpecial(data): any {
    return this.crud.post('wands/special', data);
  }

  public random(): any {
    return this.crud.post('wands/random');
  }

  public destroy(id): any {
    return this.crud.destroy('wands', id);
  }
}

import { NgModule } from '@angular/core';
import { AuthGuard } from "./guards/auth-guard.service";
import { AdminGuard } from "./guards/admin-guard.service";
import { LandingPageGuard } from './guards/landing-page-guard.service';

@NgModule({
  providers: [
    AuthGuard,
    AdminGuard,
    LandingPageGuard,
  ]
})
export class AuthModule {}

import { of as observableOf } from 'rxjs';

import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { Observable, ReplaySubject } from 'rxjs';
import { environment } from "../../../environments/environment";
import { LoginUser } from "../user/login-user";
import { User } from "../user/user";
import { UserService } from "../user/user.service";
import { ToastrService } from "../../shared/toastr/toastr.service";
import { HttpClient } from '@angular/common/http';
import * as Sentry from "@sentry/browser";
import { handleErrorResponse } from '../../shared/handle_error_response';

@Injectable()
export class AuthService {
  private _userSource = new ReplaySubject<User>(1);
  public user = this._userSource.asObservable();
  public _user: User;
  private _readReportSource = new ReplaySubject<any>(1);
  public readReport = this._readReportSource.asObservable();
  public _readReport = {};
  private roomPasswords = null;

  constructor(private http: HttpClient,
              private userService: UserService,
              private toastrService: ToastrService) {
    this._user = JSON.parse(localStorage.getItem('user'));
    this._userSource.next(this._user);
  }

  isLoggedIn(): boolean {
    let token = localStorage.getItem('token');
    return token !== undefined && token != '' && token != null;
  }

  getAuthToken() {
    return localStorage.getItem('token');
  }

  isAdmin(): boolean {
    return this.hasRole('administrator');
  }

  tutorialStatus(): string {
    if (!this._user) {
      return '';
    }

    return this._user.tutorial_status;
  }

  updatedGdprConsent(): boolean {
    if (!this._user) {
      return false;
    }

    return this._user.updated_gdpr_consent;
  }

  isCurrentUser(user_id): boolean {
    return this._user.id == user_id;
  }

  currentUser(): User {
    return this._user;
  }

  setUser(user: User) {
    if (user.deleted_at != null) {
      return this.logout();
    }

    if (user.current_character_id !== null) {
      user.current_character = user.characters.find((e) => e.id == user.current_character_id);
    }

    this._user = user;
    localStorage.setItem('user', JSON.stringify(user));
    this._userSource.next(this._user);

    if (user) {
      Sentry.configureScope((scope) => {
        scope.setUser({ "id": '' + user.id });
      });
    }
  }

  reloadUserFromServer() {
    if (this._user) {
      this.userService.reloadUser().subscribe((res) => {
        this.setUser(res.body);
      }, (res) => {
        handleErrorResponse(this.toastrService, res);
      });
    }
  }

  reloadRpPoints() {
    if (this._user) {
      this.userService.reloadRpPoints().subscribe((res) => {
        this._user.rp_points = res.data.rp_points;
        this._user.total_rp_points = res.data.total_rp_points;
        this._userSource.next(this._user);
      }, (res) => {
        handleErrorResponse(this.toastrService, res);
      });
    }
  }

  login(user: LoginUser): Observable<any> {
    let data = { login: user.login, password: user.password };

    return this.http
      .post(environment.API_ENDPOINT + 'auth', data).pipe(
        map(this.extractData));
  }

  logout(): void {
    this.http.post(environment.API_ENDPOINT + 'auth/logout', {}).subscribe(() => {
      this.http.post(environment.API_ENDPOINT + 'auth/logout', {}).subscribe();
    });

    localStorage.clear();
    this._user = null;
    this._userSource.next(this._user);
  }

  setActivityReadReport(report) {
    this._readReport = report;
    this._readReportSource.next(this._readReport);
  }

  extractData(response) {
    localStorage.setItem('token', response.data.token);
    localStorage.setItem('user', JSON.stringify(response.data.user));
    localStorage.setItem('issued', response.data.issued);
    localStorage.setItem('expires', response.data.expires);

    return response.data.user;
  }

  hasRole(role) {
    if (!this._user || !this._user.roles_array) {
      return false;
    }

    const user_roles = this._user.roles_array;

    if (user_roles.includes('administrator')) {
      return true;
    }

    if (role === 'requests') {
      return this.checkSpecificRole([
        'abilities',
        'clubhouses',
        'creatures',
        'photographer',
        'professions',
        'quests',
        'races',
        'realias',
        'registry_office',
        'secretariat',
        'quizes',
        'nurmengard',
        'rpg_coordinator',
      ]);
    }

    if (role === 'administration_access') {
      return this.checkSpecificRole([
        'abilities',
        'clubhouses',
        'library',
        'photographer',
        'nurmengard',
        'quests',
        'quizes',
        'realias',
        'rp_points',
        'surveys',
        'weather',
      ]);
    }

    return user_roles.includes(role);
  }

  private checkSpecificRole(specificRoles) {
    for (const specificRole of specificRoles) {
      if (this.hasRole(specificRole)) {
        return true;
      }
    }

    return false;
  }

  public refreshToken(): Observable<any> {
    return this.http.post(environment.API_ENDPOINT + 'auth/refresh', { token: localStorage.getItem('token') }).pipe(
      map((res) => this.processTokenResponse(res)));
  }

  private processTokenResponse(response) {
    localStorage.setItem('token', response.data.token);
    localStorage.setItem('issued', response.data.issued);
    localStorage.setItem('expires', response.data.expires);

    return observableOf({ token: response.data.token });
  }

  public saveRoomPassword(roomSlug, password) {
    this.roomPasswords[roomSlug] = password;

    localStorage.setItem('room_passwords', JSON.stringify(this.roomPasswords));
  }

  public getRoomPassword(roomSlug) {
    if (this.roomPasswords === null) {
      this.roomPasswords = JSON.parse(localStorage.getItem('room_passwords'));
    }

    if (this.roomPasswords === null) {
      this.roomPasswords = {};
    }

    return this.roomPasswords[roomSlug];
  }

  public hearbeat(location = '') {
    return this.userService.heartbeat(location);
  }
}

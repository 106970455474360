import { Injectable } from '@angular/core';
import { CrudService } from './crud.service';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class TextsService {
  constructor(private crud: CrudService, private http: HttpClient) {
  }

  index = () => this.crud.index('texts');
  show = (id) => this.crud.show('texts', id);
  create = (data) => this.crud.create('texts', data);
  update = (data) => this.crud.update('texts', data);
  byCode = (code) => this.crud.get(`texts/texts/${code}`, {});

  public names(): Observable<any> {
    return this.http.get(environment.API_ENDPOINT + 'texts/names');
  }

  public texts(codes): Observable<any> {
    return this.http.get(environment.API_ENDPOINT + 'texts/texts/' + codes);
  }
}

import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { CrudService } from './crud.service';

@Injectable()
export class ActivityReadService {

  constructor(private crud: CrudService) {
  }

  public report(): Observable<any> {
    return this.crud.get('activity_reads/report', { observe: 'response' });
  }

  public show(keys: string[]): Observable<any> {
    return this.crud.show('activity_reads', keys);
  }

  public update(type: string): any {
    return this.crud.update('activity_reads', { id: type });
  }

  public remembralSettings() {
    return this.crud.get('remembrall_settings', {});
  }

  public updateRemembrallSettings(data) {
    return this.crud.patch('remembrall_settings', data);
  }
}

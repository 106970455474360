import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from "@sentry/browser";
import { environment } from '../../environments/environment';
import { AuthService } from './auth/auth.service';
import { ReportDialogOptions } from '@sentry/browser';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor(private auth: AuthService) {
  }

  handleError(error) {
    if (environment.production) {
      console.warn('Capturing error', error);

      let eventId;

      if (
        (typeof error === 'object' && 'isTrusted' in error) ||
        (Array.isArray(error) && 'isTrusted' in error[0])
      ) {
        // silently ignore this error
        return console.error(error);
      }

      try {
        if (error instanceof Error || typeof error === 'string') {
          eventId = Sentry.captureException(error);
        }
      } catch (e) {
        console.error(e);
      }

      if (!eventId) {
        return;
      }

      let config: ReportDialogOptions = {
        eventId: eventId,
        title: "Zdá sa, že sa vyskytol problém.",
        subtitle: "Náš tím bol upozornený.",
        subtitle2: "Ak nám chcete pomôcť, povedzte nám čo sa stalo",
        labelName: "Meno",
        labelEmail: "Email",
        labelComments: "Čo sa stalo?",
        labelClose: "Zatvoriť",
        labelSubmit: "Odoslať",
        errorGeneric: "Počas odosielania nastal neznámy problém. Prosím skúste to znova.",
        errorFormEntry: "Niektoré údaje sú nevalidné. Prosím opravte problémy a skúste to znova.",
        successMessage: "Vaša správa bola odoslaná. Ďakujeme!",
      };

      if (this.auth._user && this.auth._user.username) {
        config['user'] = {
          name: this.auth._user.username,
          email: `user_${this.auth._user.id}@durmstrang.invalid`,
        };
      }

      Sentry.showReportDialog(config);
    } else {
      throw error;
    }
  }
}

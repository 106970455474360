import { FormControl, AbstractControl } from "@angular/forms";
import {  timer } from "rxjs";
import { UserService } from "../user/user.service";
import { PersonReservationsService } from '../../avardan/avatars/person_reservations/person-reservations.service';
import { switchMapTo, take, map } from 'rxjs/operators';
import { CreaturesService } from '../../avardan/creatures.service';
import { Injectable } from "@angular/core";

interface ValidationResult {
  [key:string]: boolean;
}

@Injectable()
export class CustomValidators {
  static CHARACTER_NAME_REGEX = /^([A-Za-z\u00C0-\u017F´`'.-]{2,}(\s|$)){2,}$/;

  static validateEmail(c: FormControl): ValidationResult {
    const EMAIL_REGEXP = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i;

    return EMAIL_REGEXP.test(c.value) ? null : { email: true };
  }

  static validateUsername(c: FormControl): ValidationResult {
    const USERNAME_REGEXP = /^[A-Za-z0-9\-._\u00C0-\u017F]+$/i;

    return USERNAME_REGEXP.test(c.value) ? null : { username: true };
  }

  static matchingPasswords(AC: AbstractControl) {
    let password = AC.get('password').value; // to get value in input tag
    let confirmPassword = AC.get('password_confirmation').value; // to get value in input tag

    if (password != confirmPassword) {
      AC.get('password_confirmation').setErrors({mismatch: true})
    } else {
      return null
    }
  }

  static checkUser(users: UserService, property: string) {
    return control => {
      return timer(500).pipe(
        switchMapTo(users.available(property, control.value)),
        map(res => {
          return (<{ data: string }>res).data ? null : { taken: true };
        }),
        take(1),
      );
    };
  }

  static checkAvatarPerson(reservations: PersonReservationsService) {
    return control => {
      return timer(500).pipe(
        switchMapTo(reservations.available(control.value)),
        map(res => {
          return (<{ data: string }>res).data ? null : { taken: true };
        }),
        take(1),
      );
    };
  }

  static twoAbilities(AC: AbstractControl) {
    let inborn = AC.get('inborn_ability_id').value;
    let learned1 = AC.get('acquired_ability_1_id').value;
    let learned2 = AC.get('acquired_ability_2_id').value;

    if (inborn === 1 || inborn === null) {
      AC.get('acquired_ability_1_id').setErrors(null);
      return null;
    }

    if (learned1 !== 1 && learned1 !== null && learned2 !== 1 && learned2 !== null) {
      AC.get('acquired_ability_1_id').setErrors({ two_learned: true });
      return true;
    }

    AC.get('acquired_ability_1_id').setErrors(null);
    return null;
  }

  static oneAbility(AC: AbstractControl) {
    let learned1 = AC.get('acquired_ability_1_id').value;
    let learned2 = AC.get('acquired_ability_2_id').value;

    if (learned1 !== 1 && learned1 !== null && learned2 !== 1 && learned2 !== null) {
      AC.get('acquired_ability_1_id').setErrors({ two_learned: true });
      return true;
    }

    AC.get('acquired_ability_1_id').setErrors(null);
    return null;
  }


  static checkCommand(creatures: CreaturesService) {
    return control => {
      return timer(500).pipe(
        switchMapTo(creatures.availableCommand(control.value)),
        map(res => {
          return (<{ data: string }>res).data ? null : { taken: true };
        }),
        take(1),
      );
    };
  }
}



import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../auth.service';

@Injectable()
export class LandingPageGuard implements CanActivate {
  constructor(private router: Router,
              private authService: AuthService) {
  }

  canActivate(route, state): boolean {
    if (this.authService.tutorialStatus() == 'tasks') {
      this.router.navigate(['/tutorial']);

      return false;
    }


    this.router.navigate(['/avardan/announcements']);

    return false;
  }
}

import { Injectable } from '@angular/core';
import { CrudService } from '../../shared/crud.service';

@Injectable()
export class CharacterStatusService {
  constructor(private crud: CrudService) {
  }

  public index(options = {}) {
    return this.crud.index('character-statuses', options);
  }

  public destroy(id) {
    return this.crud.destroy('character-statuses', id);
  }
}

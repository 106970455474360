import { Injectable } from '@angular/core';
import { CrudService } from '../shared/crud.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CreaturesService {

  constructor(private crud: CrudService) {
  }

  public index(options = {}): Observable<any> {
    return this.crud.index('creatures', options);
  }

  public show(id): Observable<any> {
    return this.crud.show('creatures', id);
  }

  public destroy(id): any {
    return this.crud.destroy('creatures', id);
  }

  public update(data): any {
    return this.crud.update('creatures', data);
  }

  public availableCommand(command): any {
    return this.crud.get('creatures/available_command/' + command);
  }
}

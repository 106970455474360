import { Injectable } from '@angular/core';
import { CrudService } from '../shared/crud.service';
import { Observable } from 'rxjs';

@Injectable()
export class ChatRoomService {
  constructor(private crud: CrudService) {
  }

  public index(options = {}): Observable<any> {
    if (!options['orderBy'] && !options['direction']) {
      options['orderBy'] = 'name';
      options['direction'] = 'asc';
    }
    return this.crud.index('chat_rooms', options);
  }

  public show(slug: string): Observable<any> {
    return this.crud.show('chat_rooms', slug);
  }

  public create(data): any {
    return this.crud.create('chat_rooms', data);
  }

  public update(data): any {
    return this.crud.update('chat_rooms', data);
  }

  public destroy(id): any {
    return this.crud.destroy('chat_rooms', id);
  }

  public map(type): any {
    return this.crud.index(`chat_rooms/map/${type}`);
  }

  public unlock(id, type, value): any {
    return this.crud.post(`chat_rooms/unlock/${id}`, { type: type, value: value });
  }

  public restartChatServer() {
    return this.crud.post('restart-chat-server', {})
  }

  public restartServer() {
    return this.crud.post('restart-server', {});
  }

  public updateChatMessage(data): any {
    return this.crud.patch( `chat_messages/${data.id}/update`, data);
  }

  public setOwner(data): any {
    return this.crud.patch( `chat_rooms/${data.room_id}/owner`, data);
  }
}

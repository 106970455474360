import { Component } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';
import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: '[dsg-custom-toastr]',
  templateUrl: './custom-toastr.component.html',
  styles: [`
      :host {
          background-color: #FF69B4;
          position: relative;
          overflow: hidden;
          margin: 0 0 6px;
          padding: 10px 10px 10px 10px;
          width: 300px;
          border-radius: 3px 3px 3px 3px;
          color: #FFFFFF;
          pointer-events: all;
          cursor: pointer;
      }
  `],
  animations: [
    trigger('flyInOut', [
      state('inactive', style({
          display: 'none',
          opacity: 0,
          right: '-50px',
        })
      ),
      state('active', style({
        right: 0
      })),
      state('removed', style({
        right: '-20px',
        opacity: 0,
      })),
      transition('inactive => active',
        animate('200ms ease-out')
      ),
      transition('active => removed', animate('400ms ease-out'))
    ])
  ],
})
export class CustomToastrComponent extends Toast {

  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage,
  ) {
    super(toastrService, toastPackage);
  }

  ngOnInit() {
  }
}

<div class="custom-toast">
    <div class="custom-toast-content">
        <button *ngIf="options.closeButton" (click)="remove()" class="toast-close-button" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>

        <div *ngIf="title" [class]="options.titleClass" [attr.aria-label]="title">
            {{ title }}
        </div>

        <div *ngIf="message && options.enableHtml" role="alertdialog" aria-live="polite"
             [class]="options.messageClass" [innerHTML]="message | safe:'html'">
        </div>
        <div *ngIf="message && !options.enableHtml" role="alertdialog" aria-live="polite"
             [class]="options.messageClass" [attr.aria-label]="message">
            {{ message }}
        </div>

        <div *ngIf="options.progressBar">
            <div class="toast-progress" [style.width]="width + '%'"></div>
        </div>
    </div>
</div>

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CrudService } from '../../shared/crud.service';

@Injectable()
export class MagicalTalentsService {

  constructor(private crud: CrudService) {
  }

  public edit(id: number): Observable<any> {
    return this.crud.get(`magical_talents/${id}/edit`, {});
  }

  public update(id: number, data): Observable<any> {
    return this.crud.patch(`magical_talents/${id}`, data);
  }

  public chooseTalent(id: number, type: string): Observable<any> {
    return this.crud.post(`magical_talents/${id}/talent`, { mister_talent: type });
  }
}

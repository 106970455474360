import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";
import { SuncalcService } from '../shared/suncalc.service';

@Injectable()
export class BackgroundImageService {
  private _backgroundSource = new BehaviorSubject<string>('/assets/images/site-backgrounds/' + this.durmstrangBackground());
  public background = this._backgroundSource.asObservable();

  constructor(private suncalc: SuncalcService) {
  }

  durmstrangBackground() {
    let current = new Date();
    let times = this.suncalc.getTimes();

    if (this.suncalc.checkIsDay()) {
      let timeout = times.sunset - <any>current;

      this.setupTimer(timeout);

      return this.bgImage('day');
    } else {
      let timeout = times.sunrise - <any>current;

      if (timeout < 0) {
        let tomorrow = (new Date()).getTime() + (23 * 60 * 60 * 1000); // add 23 hours, not one day because sunset is moving

        times = this.suncalc.getTimes(new Date(tomorrow));
        timeout = times.sunrise - <any>current;
      }

      this.setupTimer(timeout);
      return this.bgImage('night');
    }
  }

  setupTimer(time) {
    if (time) {
      setTimeout(() => {
        let background = this.durmstrangBackground();

        this._backgroundSource.next('/assets/images/site-backgrounds/' + background);
      }, time + 1000);
    }
  }

  bgImage(timeOfDay) {
    const month = (new Date()).getMonth();
    if (month >= 9 || month <= 2) {
      return timeOfDay === 'day' ? 'durmstrang--wday.jpg' : 'durmstrang--wnight.jpg';
    } else {
      return timeOfDay === 'day' ? 'durmstrang--day.jpg' : 'durmstrang--night.jpg';
    }
  }
}

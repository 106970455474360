import {NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { AuthModule } from "./auth/auth.module";
import { CustomValidators } from "./validators/custom-validators";
import { UserService } from "./user/user.service";
import { BackgroundImageService } from "./background-image.service";

@NgModule({
  imports: [
    CommonModule,
    AuthModule,
    HttpClientModule
  ],
  providers: [
    CustomValidators,
    UserService,
    BackgroundImageService,
  ],
  exports: [AuthModule, HttpClientModule]
})
export class CoreModule {
  constructor (@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}


import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from "../../../environments/environment";
import { CrudService } from '../../shared/crud.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class CharacterService {
  constructor(private http: HttpClient,
              private crud: CrudService,
  ) {
  }

  public index(options = {}) {
    return this.crud.index('characters', options);
  }

  public list() {
    return this.crud.index('characters/list');
  }

  public createCharacter(data) {
    return this.crud.create('characters', data);
  }

  public finishCharacterCreate(data, characterId): Observable<any> {
    return this.http.post(environment.API_ENDPOINT + 'characters/finish_create/' + characterId, data);
  }

  public show(id) {
    return this.crud.show('characters', id);
  }

  public destroy(id, data = { keepPersonReservation: false }) {
    return this.crud.destroy('characters', id, data);
  }

  public card(id) {
    return this.crud.get(`characters/${id}/card`, {});
  }

  public update(data) {
    return this.crud.update('characters', data);
  }
  public adminUpdate(data) {
    return this.crud.patch(`characters/${data.id}/admin_update`, data);
  }

  public resetAbility(id, data = {}) {
    return this.crud.post(`characters/${id}/reset_ability`, data);
  }

  public abilities() {
    return this.crud.index(`characters/abilities`, {});
  }

  public raceChanges(id) {
    return this.crud.get(`characters/${id}/race_changes`);
  }

  public deletedCharacters() {
    return this.crud.get(`characters/deleted`);
  }

  public restore(id) {
    return this.crud.patch(`characters/${id}/restore`, {});
  }

  public hide(id) {
    return this.crud.patch(`characters/${id}/hide`, {});
  }

  public comingBirthdays() {
    return this.crud.get('characters/birthdays');
  }

  public updateStatus(id, status) {
    return this.crud.patch(`characters/${id}/update-status`, { status: status });
  }
}

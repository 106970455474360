import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from "./core/auth/auth.service";
import { Subscription } from "rxjs";
import { BackgroundImageService } from "./core/background-image.service";
import { SocketService } from './shared/socket.service';
import { User } from './core/user/user';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { NgSelectConfig } from '@ng-select/ng-select';
import { SnowfService } from './shared/snowf.service';
import { WindowRefService } from './shared/window-ref.service';
import { FeatureFlagService } from 'app/core/feature-flag.service';
import { SettingsService } from './administration/settings/settings.service';

@Component({
  selector: 'dsg-root',
  template: `
      <dsg-toastr></dsg-toastr>
      <div class="socket-disconnected-notification"
           matTooltip="Aktívne spojenie so serverom bolo prerušené. Niektoré funkcie stránky nemusia fungovať správne."
           [hidden]="socketConnected || !user">
          <span class="fa-stack">
            <i class="fas fa-plug fa-stack-1x" style="color:silver"></i>
            <i class="fas fa-slash fa-stack-1x"></i>
          </span>
      </div>
      <div id="bgImage"
           [style.backgroundImage]="img ? 'url(' + img + ')' : false"
      ></div>
      <div id="wrapper" [ngClass]="{'whiteBg': whiteBackground }">
          <router-outlet></router-outlet>
      </div>
  `,
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  img = '';
  user: User;
  userSubscription: Subscription;
  socketConnectedSubscription: Subscription;
  socketConnected = false;
  whiteBackground = false;
  window;

  constructor(private bgImageService: BackgroundImageService,
              private auth: AuthService,
              private socketService: SocketService,
              private ngSelectConfig: NgSelectConfig,
              private snowfService: SnowfService,
              private settingsService: SettingsService,
              private windowRefService: WindowRefService,
              angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
  ) {
    angulartics2GoogleAnalytics.startTracking();
    this.auth.reloadUserFromServer();

    this.window = this.windowRefService.getNativeWindow();

    this.ngSelectConfig.notFoundText = 'Žiadne výsledky';
    this.ngSelectConfig.clearAllText = 'Vymazať';
    this.ngSelectConfig.addTagText = 'Vytvoriť tag';

    this.socketConnectedSubscription = this.socketService.socketConnected.subscribe((connected) => {
      this.socketConnected = connected;
    });

    if (!this.window.location.href.includes('/rooms/')) {
      this.settingsService.show('snow').subscribe((res) => {
        if (res.data.value == 'true') {
          this.snowfService.init();
        }
      }, (error) => {
      });
    }

    this.userSubscription = this.auth.user.subscribe(user => {
      this.user = user;

      if (this.user === null) {
        return;
      }

      socketService.connectUser(this.user.id);
    });
  }

  ngOnInit() {
    this.subscription = this.bgImageService.background.subscribe(img => {
      if (img) {
        this.img = img;
      }

      if (this.img == '/assets/images/site-backgrounds/durmstrang--wday.jpg') {
        this.whiteBackground = true;
      }
    });
  }

  ngOnDestroy() {
    this.socketService.unregisterSocketEvents();
    this.socketConnectedSubscription.unsubscribe();
  }

  isWinterMonth() {
    const month = new Date().getMonth();

    return month < 2 || month >= 11;
  }
}

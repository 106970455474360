import { Component, OnInit, ViewContainerRef } from '@angular/core';

import { ToastrService } from "./toastr.service";
import { ToastrService as ToastsManager } from "ngx-toastr";

@Component({
  selector: 'dsg-toastr',
  template: ''
})
export class ToastrComponent implements OnInit {
  constructor(private toastrService: ToastrService,
              public toastr: ToastsManager) {
  }

  ngOnInit() {
    this.toastrService.alertOccurred
      .subscribe(
        (data: string[]) => {
          if (data[1] == 'token_expired') {
            return;
          }

          if (typeof data[1] === 'object') {
            data[1] = JSON.stringify(data[1]);
          }

          let toastr_options = data[3] || {};

          switch (data[0]) {
            case 'success':
              this.toastr.success(data[1], data[2], toastr_options);
              break;
            case 'warning':
              this.toastr.warning(data[1], data[2], toastr_options);
              break;
            case 'info':
              this.toastr.info(data[1], data[2], toastr_options);
              break;
            case 'error':
              this.toastr.error(data[1], data[2], toastr_options);
              break;
          }
        }
      );
  }
}

import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagService {
  private featureFlags = {};

  constructor(private http: HttpClient) {
  }

  setupFeatureFlags() {
    return new Promise<void>((resolveFn, rejectFn) => {
      this.http.get(environment.API_ENDPOINT + 'feature-flags').subscribe((res:any) => {
        this.featureFlags = Object.fromEntries(res.data.split(';').map((e) => e.split(':')));
        resolveFn();
      }, (res) => {
        console.error(res.body);  
        resolveFn();
      });
    })

    return 
  }

  isFeatureFlagEnabled(name) {
    return this.featureFlags[name] === 'true';
  }
}
